<template>
  <asom-slide-menu
    v-model="showMenu"
    :title="get(orderData, 'inventoryOrderNo', '-')"
  >
    <section v-if="selectedOrder && !isLoading" class="space-y-5">
      <dl class="space-y-8 sm:space-y-0">
        <div class="sm:flex sm:py-3">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="calendar-two" size="sm" />
              <span>Order Date</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ displayUtcDate(get(orderData, "orderDate", "-")) }}
          </dd>
        </div>
        <div class="sm:flex sm:py-3">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="timelapse" size="sm" />
              <span>Elapsed Days</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ get(orderData, "elapsedDays", "-") }}
          </dd>
        </div>
        <div class="sm:flex sm:py-3">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="calendar-two" size="sm" />
              <span>Expected Delivery Date</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ displayUtcDate(get(orderData, "expectedDeliveryDate", "-")) }}
          </dd>
        </div>
        <div class="sm:flex sm:py-3">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="calendar-two" size="sm" />
              <span>Delivery Date</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ displayUtcDate(get(orderData, "deliveryDate", "-")) }}
          </dd>
        </div>
        <div class="sm:flex sm:py-3">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="swap-vertical" size="sm" />
              <span>Status</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ get(orderData, "status", "-") }}
          </dd>
        </div>
        <div class="sm:flex sm:py-3">
          <dt
            class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
          >
            <span class="flex items-center space-x-1">
              <asom-icon icon="pen" size="sm" />
              <span>Remarks</span>
            </span>
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
            {{ get(orderData, "remarks", "-") }}
          </dd>
        </div>
      </dl>
      <article>
        <asom-divider text="Items" />
        <dl class="space-y-8 sm:space-y-0">
          <div class="sm:flex sm:py-3">
            <asom-client-table
              class="inventoryOrderDetailsTable"
              :columns="[
                'inventoryTypeName',
                'inventoryGroup',
                'firstAidBox',
                'amount',
                'remarks',
              ]"
              :data="orderData.orderItems"
              :filterable="false"
              :pagination="false"
            >
              <template v-slot:header_inventoryTypeName>Item Type</template>
              <template v-slot:header_amount>Quantity</template>
              <template v-slot:remarks="scopedSlots">
                <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
              </template>
              <template v-slot:firstAidBox="scopedSlots">
                <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
              </template>
            </asom-client-table>
          </div>
        </dl>
      </article>
      <article v-if="orderData.attachments.length > 0">
        <asom-divider text="Attachments" />
        <dl class="space-y-8 sm:space-y-0">
          <div class="sm:flex sm:py-3">
            <asom-upload-file-list
              :files="orderData.attachments"
              disabled-remove
            />
          </div>
        </dl>
      </article>
      <article
        class="space-y-4"
        v-if="
          orderStatus.DELIVERY_FULFILLED.VALUE !== get(orderData, 'statusEnum')
        "
      >
        <asom-divider 
        v-if="canUpdateOrder || canApproveOrder || canRejectOrder || canReceiveOrder"
        text="Actions" />
        <section
          class="relative z-0 mt-2 grid sm:grid-cols-1 md:grid-cols-3 gap-1"
        >
          <asom-button
            v-if="
              orderStatus.DELIVERY_FULFILLED.VALUE !==
                get(orderData, 'statusEnum') && canUpdateOrder
            "
            size="sm"
            outline
            text="Update"
            @click="updateOrderClicked"
          />
          <asom-button
            v-if="
              canApproveOrder &&
                orderStatus.DELIVERY_FULFILLED.VALUE !==
                  get(orderData, 'statusEnum') &&
                orderStatus.REJECTED.VALUE !== get(orderData, 'statusEnum') &&
                orderStatus.PENDING_DELIVERY.VALUE !==
                  get(orderData, 'statusEnum')
            "
            size="sm"
            outline
            text="Approve Order"
            variant="success"
            @click="approveOrderClicked"
          />
          <asom-button
            v-if="
              canRejectOrder &&
                orderStatus.IN_REVIEW.VALUE == get(orderData, 'statusEnum')
            "
            size="sm"
            outline
            text="Reject Order"
            variant="error"
            @click="deleteOrderClicked"
          />
          <asom-button
            v-if="
              get(orderData, 'statusEnum', null) ==
                orderStatus.PENDING_DELIVERY.VALUE && canReceiveOrder
            "
            text="Move In"
            outline
            size="sm"
            @click="moveIn"
          />
        </section>
      </article>
    </section>
    <div v-else class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-modal
      title="Reject Order"
      v-model="showDeleteModal"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="deleteModalError"
        :error-message="deleteModalError"
      />
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-8">
        <div class="pl-4">
          <asom-button
            @click="onDeleteOrder"
            text="Reject"
            :loading="isSubmittingDeleteOrder"
          />
        </div>
        <div>
          <asom-button
            @click="toggleDeleteModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      title="Update Order"
      v-model="showUpdateModal"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="updateModalError"
        :error-message="updateModalError"
      />
      <asom-form-field
        label="New Expected Delivery Date"
        required
        :state="inputStates('newDateOfDelivery')"
        error="New Delivery Date is required"
      >
        <asom-input-date
          :min-date="orderDate"
          v-model="newDateOfDelivery"
          :state="inputStates('newDateOfDelivery')"
        />
      </asom-form-field>
      <div class="flex flex-row-reverse pt-8">
        <div class="pl-4">
          <asom-button
            @click="onUpdateOrder"
            text="Update"
            :loading="isSubmittingUpdate"
          />
        </div>
        <div>
          <asom-button
            @click="toggleUpdateModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal
      title="Approve Order"
      v-model="showApproveOrderModal"
      :dismissible="false"
    >
      <asom-alert
        variant="error"
        v-if="approveOrderModalError"
        :error-message="approveOrderModalError"
      />
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-8">
        <div class="pl-4">
          <asom-button
            @click="onApproveOrder"
            text="Approve"
            :loading="isSubmittingApproveOrder"
          />
        </div>
        <div>
          <asom-button
            @click="toggleApproveOrderModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </asom-slide-menu>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import inputStates from "@/mixins/inputStates";
import { required } from "@vuelidate/validators";
import {
  getInventoryOrderDetails,
  rejectOrder,
  editInventoryOrder,
  approveOrder,
} from "../../../services/inventory.service";
import { OrderStatus } from "../../../constants/APIEnums/inventoryEnums";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  props: {
    selectedOrder: {
      type: undefined,
      default: null,
    },
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      orderStatus: OrderStatus,
      error: null,
      isLoading: false,
      orderData: {
        inventoryOrderNo: "-",
        orderDate: "-",
        deliveryDate: "-",
        expectedDeliveryDate: "-",
        elapsedDays: "-",
        status: "-",
        statusEnum: "-",
        remarks: "-",
        orderItems: [],
        attachments: [],
      },
      showDeleteModal: false,
      isSubmittingDeleteOrder: false,
      deleteModalError: null,
      showUpdateModal: false,
      isSubmittingUpdate: false,
      updateModalError: null,
      newDateOfDelivery: null,
      showApproveOrderModal: false,
      isSubmittingApproveOrder: false,
      approveOrderModalError: null,
      orderDate: null,
    };
  },
  validations() {
    return {
      newDateOfDelivery: { required },
    };
  },
  computed: {
    showMenu: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    ...mapGetters({
      canApproveOrder: "auth/canApproveInventoryOrder",
      canRejectOrder: "auth/canRejectInventoryOrder",
      canReceiveOrder: "auth/canReceiveInventoryOrder",
      canUpdateOrder: "auth/canUpdateInventoryOrder",
    }),
  },
  watch: {
    modelValue: function(newValue) {
      if (newValue) {
        this.setFormData(this.selectedOrder);
        this.getOrderDetails();
      }
    },
  },
  methods: {
    get,
    displayUtcDate,
    formatDate(date) {
      if (date !== "-") return displayUtcDate(date);
      else return date;
    },
    moveIn() {
      this.$router.push({
        name: "Move In Items",
        query: {
          orderId: this.selectedOrder.inventoryOrderId,
          orderNo: this.selectedOrder.inventoryOrderNo,
        },
      });
    },
    async getOrderDetails() {
      this.error = null;
      this.isLoading = true;
      const result = await getInventoryOrderDetails({
        inventoryOrderId: get(this.selectedOrder, "inventoryOrderId"),
      });
      if (result.success) {
        this.isLoading = false;
        this.setFormData(get(result.payload, "data"));
      } else {
        this.isLoading = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    setFormData(data) {
      this.orderData.inventoryOrderNo = get(data, "inventoryOrderNo", "-");
      this.orderData.orderDate = get(data, "orderDate", "-");
      this.orderData.deliveryDate = get(data, "deliveryDate", "-");
      this.orderData.expectedDeliveryDate = get(
        data,
        "expectedDeliveryDate",
        "-"
      );
      this.orderData.elapsedDays = get(data, "elapsedDays", "-");
      this.orderData.status = get(data, "status", "-");
      this.orderData.statusEnum = get(data, "statusEnum", "-");
      this.orderData.remarks = get(data, "remarks", "-");
      this.orderData.orderItems = get(data, "orderItems", "-");
      let attachment = get(data, "inventoryAttachment", null);
      if (attachment !== null) this.orderData.attachments.push(attachment);
      else this.orderData.attachments = [];
    },
    deleteOrderClicked() {
      this.toggleDeleteModal(true);
    },
    toggleDeleteModal(value) {
      this.deleteModalError = null;
      this.showDeleteModal = value;
    },
    async onDeleteOrder() {
      this.isSubmittingDeleteOrder = true;
      this.deleteModalError = null;
      const result = await rejectOrder({
        orderId: get(this.selectedOrder, "inventoryOrderId"),
      });
      if (result.success) {
        this.isSubmittingDeleteOrder = false;
        this.toggleDeleteModal(false);
        this.getOrderDetails();
      } else {
        this.isSubmittingDeleteOrder = false;
        this.deleteModalError = result.payload;
        this.toggleDeleteModal(false);
        this.$scrollTop();
      }
    },
    updateOrderClicked() {
      this.newDateOfDelivery = get(this.orderData, "expectedDeliveryDate");
      this.orderDate = new Date(get(this.orderData, "orderDate"));
      this.toggleUpdateModal(true);
    },
    toggleUpdateModal(value) {
      this.updateModalError = null;
      this.showUpdateModal = value;
    },
    async onUpdateOrder() {
      this.updateModalError = null;
      this.v$.newDateOfDelivery.$reset();
      this.v$.newDateOfDelivery.$touch();
      if (!this.v$.newDateOfDelivery.$invalid) {
        this.isSubmittingUpdate = true;
        const result = await editInventoryOrder({
          orderId: get(this.selectedOrder, "inventoryOrderId"),
          newDateOfDelivery: this.newDateOfDelivery,
        });
        if (result.success) {
          this.isSubmittingUpdate = false;
          this.toggleUpdateModal(false);
          this.getOrderDetails();
        } else {
          this.isSubmittingUpdate = false;
          this.updateModalError = result.payload;
          this.toggleUpdateModal(false);
          this.$scrollTop();
        }
      } else {
        this.modalError = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    approveOrderClicked() {
      this.toggleApproveOrderModal(true);
    },
    toggleApproveOrderModal(value) {
      this.approveOrderModalError = null;
      this.showApproveOrderModal = value;
    },
    async onApproveOrder() {
      this.isSubmittingApproveOrder = true;
      this.approveOrderModalError = null;
      const result = await approveOrder({
        orderId: get(this.selectedOrder, "inventoryOrderId"),
      });
      if (result.success) {
        this.isSubmittingApproveOrder = false;
        this.toggleApproveOrderModal(false);
        this.getOrderDetails();
      } else {
        this.isSubmittingApproveOrder = false;
        this.approveOrderModalError = result.payload;
        this.toggleApproveOrderModal(false);
        this.$scrollTop();
      }
    },
  },
};
</script>
<style scoped>
  .inventoryOrderDetailsTable th {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .inventoryOrderDetailsTable td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
</style>